import React from 'react';
import { StandardSection } from '@commons/standard-section/StandardSection';
import { caseStudiesData } from './payload';
import { AnimatedBox } from './AnimatedBox';

import * as styles from './golang-case-studies.module.scss';

export const GolangCaseStudies = () => {
  return (
    <StandardSection
      title="Our Golang case studies"
      className={styles.section}
      classNames={{ innerWrapper: styles.innerWrapper, title: styles.mainTitle }}
      id="golang-case-study"
    >
      <div className={styles.boxesContainer}>
        {caseStudiesData.map((boxData) => (
          <AnimatedBox
            key={boxData.id}
            buttonHref={boxData.href}
            textDescription={boxData.text}
            backgroundImage={boxData.backgroundImage}
          />
        ))}
      </div>
    </StandardSection>
  );
};
