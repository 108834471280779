import React, { useState } from 'react';
import { Button } from '@commons/button/Button';
import { CustomSlider } from '@commons/custom-slider/CustomSlider';
import cx from 'classnames';

import * as styles from './control-panel.module.scss';

export const ControlPanel = ({
  services = [],
  hasImages = true,
  hasTitleAboveDescription = true,
  classes: {
    panel,
    buttonContainer,
    buttonService,
    buttonServiceActive,
    serviceDescriptionContainer,
    hidingServiceDescriptionContainer,
  } = {},
}) => {
  const [displayedService, setDisplayedService] = useState(services[0]);
  return (
    <div className={cx(styles.panel, panel)}>
      <div className={cx(styles.buttonContainer, buttonContainer)}>
        {services.map((service) => {
          return (
            <Button
              label={service.title}
              key={service.title}
              className={`${cx(styles.buttonService, buttonService)} ${
                service.id === displayedService.id ? buttonServiceActive : null
              }`}
              onClick={() => {
                setDisplayedService(service);
              }}
            />
          );
        })}
      </div>
      <div className={cx(styles.serviceDescriptionContainer, serviceDescriptionContainer)}>
        {hasImages && <img loading="lazy" src={displayedService.icon} alt={displayedService.title} />}
        {hasTitleAboveDescription && <h4>{displayedService.title}</h4>}
        <p>{displayedService.description}</p>
      </div>
      <div className={cx(styles.hidingServiceDescriptionContainer, hidingServiceDescriptionContainer)}>
        <CustomSlider settings={{ autoplay: false }} dark>
          {services.map((service) => {
            return (
              <div className={service.title} key={service.id}>
                {hasImages && <img loading="lazy" src={service.icon} alt={service.title} />}
                <h4>{service.title}</h4>
                <p>{service.description}</p>
              </div>
            );
          })}
        </CustomSlider>
      </div>
    </div>
  );
};
