import React from 'react';

export const paragraphsData = {
  firstParagraph: (
    <>
      How can you <span className="bold-text">make the development process more scalable and effective?</span> Whether
      it&apos;s for cloud services, networks, or cross-platform development, Go programming language may be the answer.
    </>
  ),
  secondParagraph: (
    <>
      Our teams of Golang developers are here to give you{' '}
      <span className="bold-text">top-of-the-line software development services</span>, just as we&apos;ve been doing
      since 2013 for tech-driven businesses all over the world.
    </>
  ),
};
