import React from 'react';

import performanceIcon from '@images/golang-development/why-use-golang/performance.svg';
import syntaxIcon from '@images/golang-development/why-use-golang/syntax.svg';
import concurrencyIcon from '@images/golang-development/why-use-golang/concurrency.svg';
import toolsIcon from '@images/golang-development/why-use-golang/tools.svg';

export const whyUseGolangData = {
  firstParagraph: (
    <>
      <span className="bold-font">“The code that grows with grace”</span> was how Andrew Gerrand introduced Golang ten
      years ago. His words ring no less true today.
    </>
  ),
  secondParagraph:
    'Designed by Googlers, and used in Uber, Twitch and Dropbox, to name some of the heavier weight converts, Go programming language features make it worth your attention for your next project.',
  thirdParagraph: (
    <>If you aren&apos;t sure if Golang is suitable for your project, download the checklist below, and find out.</>
  ),
  iconsData: [
    {
      src: performanceIcon,
      subtitle: 'High performance',
    },
    {
      src: syntaxIcon,
      subtitle: 'Simple syntax',
    },
    {
      src: concurrencyIcon,
      subtitle: 'Easy and robust concurrency',
    },
    {
      src: toolsIcon,
      subtitle: 'Accessible tools',
    },
  ],
};
