import React from 'react';
import downloadImage from '@images/golang-development/why-use-golang/choice-of-golang.png';
import { BoxWithButtonAndImage } from '../common/BoxWithButtonAndImage';

import * as styles from './choice-of-golang.module.scss';

export const ChoiceOfGolang = () => {
  const boxData = {
    firstHeading: 'Checklist',
    secondHeading: 'Is Golang the right choice for your software project?',
    buttonTitle: <>Download and find out</>,
  };

  return (
    <BoxWithButtonAndImage
      downloadImage={downloadImage}
      buttonTitle={boxData.buttonTitle}
      isForm
      classNames={{
        firstRow: styles.firstRow,
        descriptionContainer: styles.descriptionContainer,
        imageContainer: styles.imageContainer,
        buttonWithLink: styles.buttonWithLink,
      }}
    >
      <h3 className={styles.regularFont}>{boxData.firstHeading}</h3>
      <h2>{boxData.secondHeading}</h2>
    </BoxWithButtonAndImage>
  );
};
