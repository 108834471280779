import React from 'react';

import { AboutUsSection } from '@pages-impl/shared/about-us-section/AboutUsSection';

import downloadImage from '@images/golang-development/about-us.png';
import { paragraphsData } from './payload';

import * as styles from './about-us.module.scss';

export const AboutUs = () => {
  return (
    <AboutUsSection
      {...paragraphsData}
      buttonLabel="Get in touch"
      buttonHref="./#main-footer"
      imageOnRight={downloadImage}
      imageAltText="Golang development company"
      classNames={{ customImgStyles: styles.container }}
    />
  );
};
