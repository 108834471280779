import React from 'react';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { ParagraphsWithBoxes } from '../common/ParagraphsWithBoxes';
import { ChoiceOfGolang } from './ChoiceOfGolang';

import { whyUseGolangData } from './payload';

import * as styles from './why-use-golang.module.scss';

export const WhyUseGolang = () => {
  const { firstParagraph, secondParagraph, thirdParagraph, iconsData } = whyUseGolangData;
  return (
    <StandardSection
      title="Why use Golang"
      className={styles.section}
      classNames={{ innerWrapper: styles.innerWrapper, title: styles.mainTitle }}
      id="why-use-golang"
    >
      <ParagraphsWithBoxes
        firstParagraph={firstParagraph}
        secondParagraph={secondParagraph}
        methods={iconsData}
        classNames={{ boxesContainer: styles.boxesContainer, singleBoxDescription: styles.singleBoxDescription }}
      />
      <p className={styles.thirdParagraph}>{thirdParagraph}</p>
      <ChoiceOfGolang></ChoiceOfGolang>
    </StandardSection>
  );
};
