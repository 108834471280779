// extracted by mini-css-extract-plugin
export var boldFont = "our-golang-projects-module--bold-font--41e91";
export var buttonContainer = "our-golang-projects-module--button-container--70c61";
export var buttonService = "our-golang-projects-module--button-service--13aa2";
export var buttonServiceActive = "our-golang-projects-module--button-service-active--5f18e";
export var hidingServiceDescriptionContainer = "our-golang-projects-module--hiding-service-description-container--2ec49";
export var innerWrapper = "our-golang-projects-module--inner-wrapper--ed3ae";
export var mainTitle = "our-golang-projects-module--main-title--b984f";
export var panel = "our-golang-projects-module--panel--ace2e";
export var section = "our-golang-projects-module--section--0018e";
export var serviceDescriptionContainer = "our-golang-projects-module--service-description-container--767b3";