import React from 'react';

export const learnGolangData = {
  paragraph: (
    <>
      Go programming language can be overlooked as engineers opt for more popular languages like Python or C++. However,
      a language&apos;s popularity shouldn&apos;t be the main decision criterion. To spread the word about Golang and
      its unique features,{' '}
      <span className="bold-text">we have written several articles to show you the big picture.</span> Take a step back
      and have another look. In Golang, we think you&apos;ll like what you see.
    </>
  ),
  buttonsData: [
    {
      id: '1',
      title: 'Everything you should know about Golang',
      href: 'https://codilime.com/blog/what-is-go-language/',
    },
    {
      id: '2',
      title: 'How Go and Python are different from each other',
      href: 'https://codilime.com/blog/go-vs-python/',
    },
    {
      id: '3',
      title: 'Go vs. C++ - key differences',
      href: 'https://codilime.com/blog/go-vs-c-plus-plus/',
    },
  ],
};
