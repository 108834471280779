import * as styles from './payload.module.scss';

import downloadImage from '@images/golang-development/lets-talk.png';

export const letsTalkData = {
  firstHeading: 'Does this sound like a good fit for your project?',
  id: 'golang-lets-talk',
  secondHeading: 'Get in touch to talk about the details.',
  buttonLink: '/services/golang-development/#contact',
  downloadImage: downloadImage,
  classNames: {
    section: styles.section,
  },
};
