import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import { Header } from '@commons/header/Header';
import { WhyUseGolang } from '@pages-impl/golang-development/why-use-golang/WhyUseGolang';
import { AboutUs } from '@pages-impl/golang-development/about-us/AboutUs';
import { OurGolangProjects } from '@pages-impl/golang-development/our-golang-projects/OurGolangProjects';
import { OurApproach } from '@pages-impl/golang-development/our-approach/OurApproach';
import { MvpTestimonials } from '@pages-impl/mvp-for-startups';
import { LearnGolang } from '@pages-impl/golang-development/learn-golang/LearnGolang';
import { LetsTalkSection } from '@pages-impl/shared/lets-talk-section/LetsTalkSection';
import { GolangCaseStudies } from '@pages-impl/golang-development/golang-case-studies/GolangCaseStudies';

import { letsTalkData } from '@pages-impl/golang-development/payload';

import featuredImage from '@images/header-images/golang-development-services.jpg';

import * as styles from './golang-development.module.scss';

const headerTitle = 'Golang development services';

export default function Golang(props) {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    '@id': 'https://codilime.com/services/golang-development/',
    headline: 'Golang development services',
    url: 'https://codilime.com/services/golang-development/',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'Why choose Golang for the project?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'Golang was designed to be a high-performance language that is easy to scale. The language has a simple syntax that is easy to learn, and it also features robust concurrency. In addition, Golang has a number of accessible tools that make development easier.',
        },
      },
      {
        '@type': 'Question',
        name: 'How can we help you with your project?',
        acceptedAnswer: {
          '@type': 'Answer',
          text: "If you're considering Golang for your project, we can help you with the development process. We can provide you with consultations, implementation and migration plans, integrations with third-party solutions, and monitoring and maintenance of your application.",
        },
      },
    ],
  };

  return (
    <Layout
      displayTopBanner={false}
      {...props}
      contactFormType="services"
      contactFormTitle="Let’s start a new Golang-based project together"
      oldPage
    >
      <SEO
        title="Golang Development Services Company - CodiLime"
        description="Want to build a project in Golang? Choose an experienced strategic partner. We've been developing software for tech-driven businesses since 2013."
        featuredImage={featuredImage}
        schemaMarkup={schema}
      />
      <Header
        title={headerTitle}
        showImageOnMobile
        src="codilime_golang_header.jpg"
        srcSmall="codilime_golang_header_small.png"
        displayTopBanner={false}
        className={styles.header}
      />
      <AboutUs />
      <WhyUseGolang />
      <OurGolangProjects />
      <LetsTalkSection {...letsTalkData} />
      <OurApproach />
      <GolangCaseStudies />
      <MvpTestimonials title={<>What our Clients say about us</>} classNames={{ title: styles.contactTitle }} />
      <LearnGolang />
    </Layout>
  );
}
