import React from 'react';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { ControlPanel } from '@commons/control-panel/ControlPanel';
import { projectsData } from './payload';

import * as styles from './our-golang-projects.module.scss';

export const OurGolangProjects = () => {
  const { title, projects } = projectsData;

  return (
    <StandardSection
      title={title}
      className={styles.section}
      classNames={{ innerWrapper: styles.innerWrapper, title: styles.mainTitle }}
      id="our-golang-projects"
    >
      <ControlPanel
        services={projects}
        hasImages={false}
        hasTitleAboveDescription={false}
        classes={{
          panel: styles.panel,
          buttonContainer: styles.buttonContainer,
          buttonService: styles.buttonService,
          buttonServiceActive: styles.buttonServiceActive,
          serviceDescriptionContainer: styles.serviceDescriptionContainer,
          hidingServiceDescriptionContainer: styles.hidingServiceDescriptionContainer,
        }}
      />
    </StandardSection>
  );
};
