import React from 'react';
import { CheckOutSection } from '@pages-impl/shared/check-out-section/CheckOutSection';

import downloadImage from '@images/golang-development/learn-golang.webp';
import { learnGolangData } from './payload';

import * as styles from './learn-golang.module.scss';

export const LearnGolang = () => {
  return (
    <CheckOutSection
      {...learnGolangData}
      sectionTitle="Learn more about Golang"
      sectionId="learn-about-golang"
      downloadImage={downloadImage}
      imageAltText="Expert content about golang language"
      classNames={{ section: styles.section, imageContainer: styles.imageContainer }}
    />
  );
};
