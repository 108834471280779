import React from 'react';

export const projectsData = {
  title: 'Projects we do using Golang',
  projects: [
    {
      id: '1',
      title: 'Cloud-native app development',
      description: (
        <>
          Whether you are considering{' '}
          <span className="bold-font">
            building an application in a public, private, hybrid, or multi-cloud environment,
          </span>{' '}
          we help you create the app in each of them. Our team has a lot of experience migrating on-prem apps to the
          cloud, designing and maintaining cloud-native architecture, and managing cloud services. In other words,
          everything you&apos;ll need to quickly meet customer needs and shorten the time to market.
        </>
      ),
    },
    {
      id: '2',
      title: 'Microservices & serverless',
      description: (
        <>
          Microservices and serverless are other attributes of the cloud-native approach.{' '}
          <span className="bold-font">Microservices</span> allow you to divide app construction into smaller tasks,
          which can be written in a different language or with different technologies and still performed together.
          <br />
          <span className="bold-font">Serverless applications</span> make better use of computing resources and can
          respond to user demand quicker. Of course, this doesn&apos;t mean there&apos;s no server at all— but it&apos;s
          managed by the cloud provider. The most important advantages of serverless apps are that they easily scale
          both vertically and horizontally and they are not bound to a single pre-specified server.
        </>
      ),
    },
    {
      id: '3',
      title: 'REST API development',
      description: (
        <>
          If one of your main priorities in building the app is flexibility,{' '}
          <span className="bold-font">REST APIs</span> may be the solution you&apos;re looking for. This application
          programming interface provides a flexible, lightweight way to connect the components in microservices
          architectures. Our developers employ the highest standards in designing secure and easy-to-use REST APIs.
        </>
      ),
    },
    {
      id: '4',
      title: 'Service Mesh and K8s development',
      description: (
        <>
          Good microservice applications depend on two solutions to work robustly at scale:{' '}
          <span className="bold-font">service mesh and Kubernetes.</span> Service mesh secures and monitors network
          traffic and enables fast and smooth communication between services at the infrastructure level. Kubernetes
          manages the entire application via containers. Keeping on top of these two technologies at once may sound like
          a daunting task, but you don&apos;t have to wander the technology wilderness alone. We provide consultations,
          implementation and migration plans, integrations with 3rd-party solutions, and monitoring and maintenance of
          your application.
        </>
      ),
    },
  ],
};
