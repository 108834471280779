import React from 'react';
import img1 from '@images/golang-development/case-studies/1.png';
import img2 from '@images/golang-development/case-studies/2.png';
import img3 from '@images/golang-development/case-studies/3.png';
import img4 from '@images/golang-development/case-studies/4.png';

export const caseStudiesData = [
  {
    id: '1',
    text: (
      <>
        We{' '}
        <span className="bold-text">
          automated the onboarding process of virtual private clouds to the client&apos;s network observability
          platform.{' '}
        </span>
        One of the steps we took was implementing a Terraform provider in Golang. This made it possible to automate the
        configuration of flow log export from cloud deployment to observability platform.
      </>
    ),
    href: '/case-study/building-automation-module-for-network-observability-solution/',
    backgroundImage: img1,
  },
  {
    id: '2',
    text: (
      <>
        Our software and DevOps engineers helped a start-up{' '}
        <span className="bold-text">build a cloud-native microservices security platform</span> feature to protect
        microservices and give users a clear view of traffic.
      </>
    ),
    href: 'https://codilime.com/case-studies/',
    backgroundImage: img2,
  },
  {
    id: '3',
    text: (
      <>
        In another case, our client wanted to create a product that was inexpensive and simple to install and configure.
        The solution we helped them build{' '}
        <span className="bold-text">
          facilitated the deployment of their customers&apos; network management application.
        </span>
      </>
    ),
    href: 'https://codilime.com/case-study/automatic-data-center-and-low-level-network-infrastructure-management-solution/',
    backgroundImage: img3,
  },
  {
    id: '4',
    text: (
      <>
        Finally, how about <span className="bold-text">an open-source tool to streamline Spinnaker usage?</span> We
        built one with Golang as a core technology. In this case, we also provided our client with expertise in DevOps
        automation and deploying CI/CD pipelines.
      </>
    ),
    href: 'https://codilime.com/case-study/designing-open-source-ci-tool-to-simplify-gitops-with-spinnaker/',
    backgroundImage: img4,
  },
];
