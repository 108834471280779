import React from 'react';
import Image from '@commons/image/Image';
import { Button } from '@commons/button/Button';

import * as styles from './animated-box.module.scss';

export const AnimatedBox = ({ textDescription, buttonHref, backgroundImage }) => {
  return (
    <div className={styles.boxContainer}>
      <div className={styles.backgroundImage}>
        <Image image={backgroundImage} alt={`Thumbnail of a Golang case study`} />
      </div>
      <div className={styles.content}>
        <div className={styles.description}>{textDescription}</div>
        <div className={styles.buttonContainer}>
          <Button className={styles.button} label="Read more" href={buttonHref} />
        </div>
      </div>
    </div>
  );
};
